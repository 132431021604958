import {
  fetchInsuranceQuotes,
  fetchTravelProtectionQuotes,
  getInsuranceProducts,
  getInsuranceProductsBySubscription, getInsuranceUpdateQuotes, GetQuotePayload, getTravelProtectionProducts,
  sendOptOutRequestToCg,
  upgradeInsurance,
} from 'api/insurance'
import { getCheckoutInsuranceProducts, getInsuranceQuoteRequest } from 'checkout/selectors/request/insurance'
import { getInsuranceItems } from 'checkout/selectors/view/insurance'
import { RESERVATION_TYPE_BOOK_LATER } from 'constants/reservation'
import { providerIsCoverGenius } from 'lib/insurance/insuranceHelpers'
import { API_CALL, RESET_INSURANCE, UPDATE_INSURANCE_PAYMENT_ITEM } from './actionConstants'
import {
  FETCH_CHECKOUT_INSURANCE_QUOTES,
  FETCH_INSURANCE_PRODUCTS,
  FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID, FETCH_INSURANCE_UPGRADE_QUOTES,
  OPT_OUT_OF_QUOTE,
  UPGRADE_INSURANCE,
} from './apiActionConstants'
import { pushWithRegion } from './NavigationActions'

export function fetchCheckoutInsuranceQuotes(customisedItem?: {productId: string, policyIds: Array<string>}, customAmount?: number) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (state.insurance.products.length > 0) {
      const productIds = customisedItem ? [customisedItem.productId] : state.insurance.products.map(product => product.id)
      const insuranceQuote = getInsuranceQuoteRequest(state)
      const request = {
        ...insuranceQuote,
        product_id: customisedItem?.productId,
        policy_ids: customisedItem?.policyIds,
        product_ids: customisedItem ? state.insurance.products.map(product => product.id) : undefined,
        total_price: customAmount ?? insuranceQuote?.total_price,
      }

      dispatch({
        type: API_CALL,
        api: FETCH_CHECKOUT_INSURANCE_QUOTES,
        request: () => fetchInsuranceQuotes(request),
        productIds,
      })
    }
  }
}

export function fetchCheckoutTravelProtectionQuotes(customisedItem?: {productId: string, policyIds: Array<string>}, customAmount?: number) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (state.insurance.products.length > 0) {
      const productIds = customisedItem ? [customisedItem.productId] : state.insurance.products.map(product => product.id)
      const insuranceQuote = getInsuranceQuoteRequest(state)
      const request = {
        ...insuranceQuote,
        product_id: customisedItem?.productId,
        policy_ids: customisedItem?.policyIds,
        product_ids: customisedItem ? state.insurance.products.map(product => product.id) : undefined,
        total_price: customAmount ?? insuranceQuote?.total_price,
      }

      // Travel protection doesn't need the Covid flag
      delete request.includingCovid

      dispatch({
        type: API_CALL,
        api: FETCH_CHECKOUT_INSURANCE_QUOTES,
        request: () => fetchTravelProtectionQuotes(request),
        productIds,
      })
    }
  }
}

export function fetchInsuranceQuotesForExistingBooking(orderId: string, details: Partial<GetQuotePayload>) {
  return (dispatch, getState) => {
    const state: App.State = getState()
    if (state.insurance.fetchingQuotes) {
      // already fetching quotes
      return
    }

    if (details.isCruise && !details.travellers_details?.number_of_adults) {
      // to avoid 422 The number of adults or seniors must be greater or equal to 1
      return
    }

    // Logic assumes that a user can only ever update dates for one offer at a time so the item index required to grab the reservationType will always be 0
    const reservationOrder = state.orders.orders[orderId]?.items[0]

    const requestDetails:Partial<GetQuotePayload> = {
      ...details,
      country_code: state.geo.currentRegionCode as GetQuotePayload['country_code'],
      currency: state.geo.currentCurrency,
      reservationType: reservationOrder?.reservationType === RESERVATION_TYPE_BOOK_LATER ?
        RESERVATION_TYPE_BOOK_LATER :
        undefined,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CHECKOUT_INSURANCE_QUOTES,
      request: () => fetchInsuranceQuotes(requestDetails),
      // assume this fetches quotes for all products?
      productIds: state.insurance.products.map(product => product.id),
    })
  }
}

export function fetchTravelProtectionQuotesForExistingBooking(details: Partial<GetQuotePayload>) {
  return (dispatch, getState) => {
    const state: App.State = getState()
    if (state.insurance.fetchingQuotes) {
      // already fetching quotes
      return
    }

    if (details.isCruise && !details.travellers_details?.number_of_adults) {
      // to avoid 422 The number of adults or seniors must be greater or equal to 1
      return
    }

    // Travel protection doesn't need the Covid flag
    delete details.includingCovid

    const requestDetails = {
      ...details,
      country_code: state.geo.currentRegionCode as GetQuotePayload['country_code'],
      currency: state.geo.currentCurrency,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CHECKOUT_INSURANCE_QUOTES,
      request: () => fetchTravelProtectionQuotes(requestDetails),
      // assume this fetches quotes for all products?
      productIds: state.insurance.products.map(product => product.id),
    })
  }
}

export function sendOptOutInsuranceItemsGlobalCheckout() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const insuranceItems = getInsuranceItems(state)
    const products = getCheckoutInsuranceProducts(state)
    const { insurance, geo } = state

    if (!providerIsCoverGenius(geo.currentRegionCode) ||
      insuranceItems.length > 0 ||
      !products.hasRequiredData
    ) {
      return
    }

    if (insurance.quotes.length > 0) {
      // Simply take the first quote - logic taken from old implementation
      const quoteId = insurance.quotes[0].id

      // In case where the full protection product is present, XCover requires sending the opt out with this product.
      // Otherwise, we should send the first product id.
      const fullProtectionProduct = products.data.find(product => product.isFullProtection)
      const productIdList = fullProtectionProduct ?
          [fullProtectionProduct.id] :
          (products.data && products.data.length > 0 ? [products.data[0].id] : [])

      dispatch({
        api: OPT_OUT_OF_QUOTE,
        type: API_CALL,
        request: () => sendOptOutRequestToCg(quoteId, productIdList),
      })
    }
  }
}

export function fetchProducts({
  isCustomisable = false,
  isDomestic = false,
  isCruise = false,
}: {
  isCustomisable?: boolean;
  isDomestic?: boolean;
  isCruise?: boolean
} = {}) {
  return (dispatch, getState) => {
    const state: App.State = getState()

    dispatch({
      type: API_CALL,
      api: FETCH_INSURANCE_PRODUCTS,
      request: () => getInsuranceProducts({
        regionCode: state.geo.currentRegionCode,
        isCustomisable,
        isDomestic,
        isCruise,
      }),
    })
  }
}

export function fetchTravelProtectionProducts({
  isDomestic = false,
  isCruise = false,
}: {
  isDomestic?: boolean;
  isCruise?: boolean
} = {}) {
  return (dispatch, getState) => {
    const state: App.State = getState()

    dispatch({
      type: API_CALL,
      api: FETCH_INSURANCE_PRODUCTS,
      request: () => getTravelProtectionProducts({
        regionCode: state.geo.currentRegionCode,
        isDomestic,
        isCruise,
      }),
    })
  }
}

export function fetchProductsBySubscriptionId(subscriptionId: string) {
  return {
    type: API_CALL,
    api: FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID,
    request: () => getInsuranceProductsBySubscription(subscriptionId),
  }
}

export function resetInsurance() {
  return {
    type: RESET_INSURANCE,
  }
}

export function fetchUpgradeQuote(
  orderId: string,
  updates: App.InsuranceUpgrade,
) {
  return dispatch => {
    dispatch({
      type: API_CALL,
      api: FETCH_INSURANCE_UPGRADE_QUOTES,
      updates,
      request: () => getInsuranceUpdateQuotes(orderId, updates),
    })
  }
}

export function updatePaymentItem(insuranceItem: App.InsurancePaymentItem) {
  return {
    type: UPDATE_INSURANCE_PAYMENT_ITEM,
    data: insuranceItem,
  }
}

export function finalizeInsuranceUpgrade(orderId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const item = state.insurance.payment?.item

    if (item?.updateId && item?.transactionKey && item?.coverAmount && item?.amount !== undefined) {
      const updates = {
        updateId: item.updateId,
        coverAmount: item.coverAmount,
        transactionKey: item.transactionKey,
        total: item.amount,
      }
      dispatch({
        type: API_CALL,
        api: UPGRADE_INSURANCE,
        request: () => upgradeInsurance(orderId, updates).then(() => {
          dispatch(pushWithRegion(`/account/my-escapes/order/${orderId}`))
        }),
      })
    }
  }
}
