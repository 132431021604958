import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import request from 'api/requestUtils'
import { getInsuranceProvider, providerIsCoverGenius } from 'lib/insurance/insuranceHelpers'
import { paths } from '@luxuryescapes/contract-svc-insurance'
import qs from 'qs'
import { bookingProtectionQuoteMap } from './mappers/insuranceMap'

export type GetQuotePayload = paths['/api/insurance/quote']['post']['parameters']['body']['payload']
export type GetQuotePayloadResponse = any // TODO

function serverQuoteToLocal(
  serverQuote,
  serverProduct,
  numberOfSeniors?: number,
): App.InsuranceQuote {
  return {
    id: serverQuote.id,
    destinationCountries: serverQuote.destination_countries,
    endDate: serverQuote.trip_end_date,
    startDate: serverQuote.trip_start_date,
    currencyCode: serverQuote.currency,
    productId: serverProduct.product_id,
    total: serverProduct.total,
    luxPlusTotal: serverProduct.lux_plus_total,
    excess: serverProduct.excess_amount,
    pds: serverProduct._links.pds.href,
    travellerCount: serverQuote.traveller_count,
    totalPerPerson: serverProduct.total / serverQuote.traveller_count,
    luxPlusTotalPerPerson: serverProduct.lux_plus_total / serverQuote.traveller_count,
    ageLimit: serverProduct.age_limit,
    disclaimer: serverProduct.disclaimer,
    seniorsApplicable: serverProduct.seniors_applicable,
    numberOfSeniors,
    luxPlusDiscountPercentage: serverQuote.lux_plus_discount_percentage,
    luxPlusDiscountValueDisplayThreshold: serverQuote.lux_plus_discount_value_display_threshold,
    mobileAppOnlyPrice: serverProduct.mobile_price_total ?? 0,
    mobileAppOnlyPricePerPerson: serverProduct.mobile_price_total > 0 ? serverProduct.mobile_price_total / serverQuote.traveller_count : 0,
    policyQuotes: serverProduct.policy_quotes?.map((policyQuote) => ({
      policy: policyQuote.policy,
      price: policyQuote.price,
      luxPlusPrice: policyQuote.lux_plus_price,
      pricePerPerson: policyQuote.price / serverQuote.traveller_count,
      luxPlusPricePerPerson: policyQuote.lux_plus_price / serverQuote.traveller_count,
      mobileAppOnlyPricePerPerson: policyQuote.mobile_price > 0 ? policyQuote.mobile_price / serverQuote.traveller_count : 0,
      mobileAppOnlyPrice: policyQuote.mobile_price ?? 0,
      pds: policyQuote.pds,
    })),
  }
}

/**
 * Used by the new checkout
 */
export async function fetchInsuranceQuotes(payload: Partial<GetQuotePayload>): Promise<App.InsuranceQuote> {
  const response = await request.post<App.ApiResponse<GetQuotePayloadResponse>, Partial<GetQuotePayload>>(
    '/api/insurance/quote',
    payload,
    { credentials: 'include' },
  )
  if (response.result) {
    return response.result.products.map((product: unknown) => ({
      ...serverQuoteToLocal(response.result, product, payload.travellers_details.number_of_seniors),
    }))
  }

  throw response.message
}

export async function fetchTravelProtectionQuotes(payload: Partial<GetQuotePayload>): Promise<App.InsuranceQuote> {
  const response = await request.post<App.ApiResponse<GetQuotePayloadResponse>, Partial<GetQuotePayload>>(
    '/api/insurance/travel-protection/quote',
    payload,
    { credentials: 'include' },
  )
  if (response.result) {
    return response.result.products.map((product: unknown) => ({
      ...serverQuoteToLocal(response.result, product),
    }))
  }

  throw response.message
}

/**
 * Legacy Checkout
 */
export const getInsuranceQuote = (regionCode: string, insurance: any, totalPrice: number) => {
  const {
    brand,
    countryCode,
    currencyCode,
    startDate,
    endDate,
    destinationCountries,
    travellerCounts,
    numberOfSeniors = 0,
    reservationType,
  } = insurance

  const provider = getInsuranceProvider(regionCode)
  const data = {
    brand,
    provider,
    country_code: countryCode,
    currency: currencyCode,
    trip_start_date: moment.utc(startDate).format(ISO_DATE_FORMAT),
    trip_end_date: moment.utc(endDate).format(ISO_DATE_FORMAT),
    destination_countries: destinationCountries,
    total_price: totalPrice,
    includingCovid: true,
  } as any

  // if the reservation type is BNBL the insurance provider requires this to be sent in the quote request
  if (reservationType) {
    data.reservationType = reservationType
  }

  // traveller_details interface depends on the insurance provider.
  if (providerIsCoverGenius(regionCode)) {
    data.travellers_details = {
      number_of_adults: travellerCounts.adults - numberOfSeniors,
      number_of_children: travellerCounts.children,
      number_of_infants: travellerCounts.infants,
      number_of_seniors: numberOfSeniors,
    }
  }

  return request.post<App.ApiResponse<any>, unknown>('/api/insurance/quote', data, { credentials: 'include' })
    .then<Array<App.InsuranceQuote>>(response => response.result.products.map(product => ({
      ...serverQuoteToLocal(response.result, product, numberOfSeniors),
    })))
}

function serverProductToLocal(serverProduct): App.InsuranceProduct {
  return {
    id: serverProduct.id,
    name: serverProduct.name,
    description: serverProduct.description,
    benefits: serverProduct.items,
    documents: {
      pds: serverProduct.documents.pds,
      emc: serverProduct.documents.emc,
      pp: serverProduct.documents.pp,
      apply: serverProduct.documents.apply,
      benefits: serverProduct.documents.benefits,
      ausResidency: serverProduct.documents.aus_residency,
    },
    keyBenefits: serverProduct.key_benefits,
    estimateBracket: serverProduct.estimateBracket,
    quotable: true,
    benefitsHeading: serverProduct.key_benefits_heading,
    seniorsApplicable: serverProduct.seniors_applicable,
    recommended: serverProduct.recommended,
    isFullProtection: serverProduct.is_full_protection,
    policyNames: serverProduct.policies || [],
    policyOptions: serverProduct.policy_options?.map(serverPolicyOptionToLocal),
    ageLimit: serverProduct.age_limit,
  }
}

function serverPolicyOptionToLocal(serverPolicyOption): App.InsuranceProductPolicyOption {
  return {
    policy: serverPolicyOption.policy,
    name: serverPolicyOption.name,
    keyBenefits: serverPolicyOption.key_benefits,
  }
}

export const getInsuranceProducts = ({
  regionCode,
  isCustomisable,
  isDomestic,
  isCruise,
}: {
  regionCode: string,
  isCustomisable?: boolean,
  isDomestic?: boolean,
  isCruise?: boolean,
}) => {
  const provider = getInsuranceProvider(regionCode)
  if (provider) {
    const url = isCustomisable ? '/api/insurance/customise-products' : '/api/insurance/products'
    const queryString = qs.stringify({
      provider,
      isDomestic,
      includingCovid: true,
      isCruise,
    })
    return request.get<App.ApiResponse<Array<unknown>>>(`${url}?${queryString}`)
      .then(response => response.result.map(serverProductToLocal))
  }

  return Promise.resolve([])
}

export const getTravelProtectionProducts = ({
  regionCode,
  isDomestic,
  isCruise,
}: {
  regionCode: string,
  isDomestic?: boolean,
  isCruise?: boolean,
}) => {
  const provider = getInsuranceProvider(regionCode)
  if (provider) {
    const url = isCruise ? '/api/insurance/travel-protection/products' : '/api/insurance/travel-protection/customise-products'
    const queryString = isCruise ? qs.stringify({
      provider,
      isDomestic,
      isCruise,
    }) : qs.stringify({
      provider,
      isDomestic,
    })
    return request.get<App.ApiResponse<Array<unknown>>>(`${url}?${queryString}`)
      .then(response => response.result.map(serverProductToLocal))
  }

  return Promise.resolve([])
}

export function getInsuranceProductsBySubscription(subscriptionId: string) {
  return request.get<App.ApiResponse<Array<unknown>>>(
    `/api/insurance/subscription/${subscriptionId}/products`, { credentials: 'include' },
  )
    .then(response => response.result.map(serverProductToLocal))
}

export const sendOptOutRequestToCg = (id: string, productsIds: Array<string>) => {
  const optOutData = {
    product_ids: productsIds,
  }
  return request.post(`/api/insurance/quote/${id}/opt_out`, optOutData, { credentials: 'include' })
}

export const getInsuranceUpdateQuotes = (
  orderId: string,
  updateData: App.InsuranceUpgrade,
): Promise<App.InsuranceUpgradeQuote> => {
  const url = `/api/orders/${orderId}/insurance/quote-upgrade`
  const data = {
    subscription_id: updateData.subscriptionId,
    trip_start_date: moment(updateData.startDate).format('YYYY-MM-DD'),
    trip_end_date: moment(updateData.endDate).format('YYYY-MM-DD'),
    cover_amount: updateData.coverAmount,
    travellers_details: updateData.travellerDetails.map(traveller => ({
      surname: traveller.lastName,
      first_name: traveller.firstName,
      age_range: traveller.ageRange,
    })),
  }

  return request.post<App.ApiResponse<any>, any>(url, data, { credentials: 'include' }).then(response => mapUpdateQuote(response.result))
}

function mapUpdateQuote(serverQuote): App.InsuranceUpgradeQuote {
  return {
    id: serverQuote.id,
    updateId: serverQuote.update_id,
    totalPriceDiff: serverQuote.total_price_diff,
    total: serverQuote.total_price,
  }
}

export async function upgradeInsurance(
  orderId: string,
  updates: App.InsuranceUpgradeRequest,
): Promise<void> {
  const url = `/api/orders/${orderId}/insurance/upgrade`
  await request.post(url, updates, { credentials: 'include' })
}

export type RefundProtectQuoteRequest = paths['/api/insurance/refund-protect/quote']['post']['parameters']['body']['payload']
export type RefundProtectQuoteResponse = paths['/api/insurance/refund-protect/quote']['post']['responses']['200']['content']['application/json']
export type RefundProtectQuotePatchRequest = paths['/api/insurance/refund-protect/quote']['patch']['parameters']['body']['payload']

export async function getBookingProtectionQuote(payload: RefundProtectQuoteRequest): Promise<App.BookingProtectionQuote> {
  return request.post<RefundProtectQuoteResponse, RefundProtectQuoteRequest>('/api/insurance/refund-protect/quote', payload, { credentials: 'include' })
    .then(response => bookingProtectionQuoteMap(response.result))
}

export async function patchBookingProtectionQuote(payload: RefundProtectQuotePatchRequest): Promise<App.BookingProtectionQuote> {
  return request.patch<RefundProtectQuoteResponse, RefundProtectQuotePatchRequest>('/api/insurance/refund-protect/quote', payload, { credentials: 'include' })
    .then(response => bookingProtectionQuoteMap(response.result))
}
